import React, { useMemo } from "react";

const SubTableLicenseNo = ({ row }) => {
  let goldData = [];
  let silverData = [];
  let platinumData = [];
  useMemo(() => {
    goldData = row.purchasedLicenses.filter(
      (license) => license.licenseDetails.type === "GOLD"
    );
    silverData = row.purchasedLicenses.filter(
      (license) => license.licenseDetails.type === "SILVER"
    );
    platinumData = row.purchasedLicenses.filter(
      (license) => license.licenseDetails.type === "Platinum"
    );
  }, [row.purchasedLicenses]);

  return (
    <>
      {/* {row.purchasedLicenses.map((license, index) => ( */}
      {goldData && goldData.length > 0 && (
        <div key={goldData[0]._id}>{goldData[0].quantity}</div>
      )}
      {silverData && silverData.length > 0 && (
        <div key={silverData[0]._id}>{silverData[0].quantity}</div>
      )}
      {platinumData && platinumData.length > 0 && (
        <div key={platinumData[0]._id}>{platinumData[0].quantity}</div>
      )}

      {/* ))} */}
    </>
  );
};

export default (cell, row, rowIndex) => <SubTableLicenseNo row={row} />;
